body {
  padding: 0 !important;
}

button.share-close-button {
  position: absolute;
  right: 0;
  top: 0;
}

[dir='rtl'] button.share-close-button {
  left: 0;
  right: unset;
}

.container {
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2rem;
  border: 1px solid black;
  border-radius: 1rem;
}

button {
  background: none;
  border: none;
  padding: 0;
}

.share-header {
  text-align: center;
  font-size: 1.1rem;
  margin-bottom: 5px;
}

.icons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.row-group {
  margin-top: 0.4rem;
  white-space: nowrap;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border-radius: 1000rem;
  margin: 0 0.45rem 0 0.45rem;
}

.icon-name {
  overflow: hidden;
  white-space: nowrap;
  margin-left: 0;
  max-width: 0px;
  transition: max-width 1s, margin 0.3s;
  font-size: 0.88rem;
  cursor: pointer;
}

.facebook {
  background-color: #3b5998;
  color: white;
}

.twitter {
  background-color: #00aced;
  color: white;
}

.gray {
  background-color: #686565;
  color: white;
}

.whatsApp {
  background-color: #25d366;
  color: white;
}

.messenger {
  background-color: #2196f3;
  color: white;
}

@media (min-width: 601px) {
  .icon {
    margin: 0 0.35rem 0 0.35rem;
  }

  .icon:hover > .icon-name {
    max-width: 130px;
    margin-left: 0.3rem;
  }

  button:focus-visible > .icon > .icon-name {
    max-width: 130px;
    margin-left: 0.3rem;
  }
}

@media (max-width: 600px) {
  .container {
    width: 80%;
  }

  .icons {
    flex-wrap: wrap;
  }
}
