.error-helper-text {
  display: flex;
  color: #c6252b;
  margin-bottom: 0.5rem;
}

.error-message {
  margin-top: 2px;
  font-family: 'Open Sans';
}
