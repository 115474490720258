.email-results-container {
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 3rem;
  color: black;
  font-family: Roboto Slab, serif;
}

.copy-results-text {
  font-weight: bold;
  text-decoration: underline;
}

.bottom-margin {
  margin-bottom: 1rem;
}

.copy-button-and-text {
  display: flex;
  align-items: center;
  font-family: Roboto Slab, serif;
  font-size: 1rem;
  cursor: pointer;
}

.copy-button-and-text:hover {
  color: var(--secondary-color);
}

.send-button {
  align-items: center;
  align-self: end;
  background-color: var(--secondary-color);
  border: 1px solid var(--secondary-color);
  border-radius: 1.5625rem;
  color: #fff;
  font-family: Roboto Slab, serif;
  font-weight: bold;
  justify-content: center;
  padding: 0.25rem 0.75rem;
  margin: 0.25rem 1rem;
}

.send-button:hover {
  color: var(--secondary-color);
  background-color: #fff;
  cursor: pointer;
}

.flex-direction-column {
  display: flex;
  flex-direction: column;
}

div.MuiFormControl-root {
  margin-bottom: 0.5rem;
}

@media (max-width: 790px) {
  .email-results-container {
    width: 90%;
    padding: 1.5rem;
  }
}
