.member-added-container {
  display: inline-flex;
  flex-direction: column;
  flex-wrap: wrap;
  outline: 1px solid black;
  border-radius: 10px;
  padding: 0.7rem;
  margin: 0.5rem;
  position: relative;
}

.household-member-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.current-household-member {
  outline: 3px solid var(--secondary-color);
  box-shadow: 1px 1px 10px rgb(0 0 0 / 0.4);
}

.member-added-relationship {
  color: var(--primary-color);
}

.household-data-sub-header {
  margin-top: 0.5rem;
}

.question-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

@media (max-width: 600px) {
  .member-added-container {
    display: inline-block;
    padding: 0.5rem;
    margin: 0.5rem;
    width: 90%;
  }

  .member-added-relationship {
    display: inline;
    padding-right: 0.5rem;
  }

  .member-added-age {
    display: inline-block;
  }

  .member-added-income {
    display: none;
  }
}
