.error-container {
  text-align: center;
  width: 60%;
  margin: auto;
}

img.error-icon {
  color: var(--primary-color);
  font-size: 4rem;
  margin: 3rem 0 1rem 0;
  width: 4rem;
  height: 4rem;
}

.error-header {
  font-size: 2rem;
  font-weight: 700;
  font-family: 'Roboto Slab', sans-serif;
  color: var(--secondary-color);
  margin-bottom: 1rem;
}

button.error-button {
  margin-top: 3rem;
}
