.option-card.MuiPaper-root {
  display: flex;
  flex-direction: column;
  border: 0.05px solid #6d6e71;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  height: 15rem;
  background-color: #ffffff;
}

.option-card:hover {
  background-color: var(--hover-color);
  color: var(--option-card-hover-font-color);
}

.selected-option-card.MuiPaper-root {
  background-color: var(--primary-color);
}

.option-card-text {
  color: #ffffff;
}

.option-card-icon {
  height: 4.375rem;
  width: 4.375rem;
}

.option-card-icon path {
  fill: var(--icon-color);
}

.checkmark {
  height: 1.75rem;
  width: 1.75rem;
  margin: -2.25rem 0.5rem 0.5rem 0;
}

.cls-checkmark-fill {
  fill: var(--icon-color);
}

/* This css rule targets the svg class of the milk carton fill */
.food-icon {
  stroke: var(--icon-color);
  stroke-miterlimit: 10;
  stroke-width: 0.313rem;
}

.option-cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1.875rem;
  max-width: 50.375rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

@media (max-width: 38.125rem) {
  div.option-cards-container {
    gap: 0.938rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .card-action-area.MuiButtonBase-root,
  .option-card.MuiPaper-root,
  .selected-option-card.MuiPaper-root {
    width: 100%;
    height: 10rem;
  }

  .option-card-image {
    height: 3.5rem;
    width: 3.5rem;
  }

  div.sub-header,
  h2.sub-header,
  .question-label {
    font-size: 1.5rem;
  }

  p.question-label {
    font-size: 1rem;
  }

  .checkmark {
    height: 1.5rem;
    width: 1.5rem;
    margin: -2rem 0.5rem 0.5rem 0;
  }
}
