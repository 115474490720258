.delete-button-container {
  display: flex;
  justify-content: flex-end;
}

.expense-block-textfield {
  margin-left: 8px;
}

.delete-button.MuiButton-root {
  min-width: 0;
}

.prev-save-continue-buttons {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 420px) {
  .delete-button.MuiButton-root {
    width: 1rem;
    height: 1.5rem;
    margin-bottom: 1rem;
  }
}
